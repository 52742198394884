import './App.css';
import Space from './components/space/space';

function App() {
	return (
		<div className="App">
			<Space></Space>
		</div>
	);
}

export default App;
