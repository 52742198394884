import React from 'react';
import * as THREE from 'three';
import './space.css';

class Space extends React.Component {
	constructor(props) {
		super(props);
		this.canvasRef = React.createRef();
	}

	componentDidMount() {
		const canvas = this.canvasRef.current;
		this.view = new View(canvas);
	}

	render() {
		return (
			<div className="space-container">
				<canvas ref={this.canvasRef}></canvas>
			</div>
		);
	}
}

class View {
	constructor(canvasRef) {
		this.scene = new THREE.Scene();
		this.renderer = new THREE.WebGLRenderer({ canvas: canvasRef, antialias: false });
		this.renderer.setSize(window.innerWidth, window.innerHeight);

		this.camera = new THREE.PerspectiveCamera(
			50,
			window.innerWidth / window.innerHeight,
			0.1,
			2000
		);
		this.camera.position.set(0, 0, 0);
		this.camera.position.z = 50;

		this.mouseX = 0;
		this.mouseY = 0;
		this.cameraX = this.camera.position.x;
		this.cameraY = this.camera.position.y;
		this.cameraZ = this.camera.position.z;
		this.pinchZoomDistance = 0;
		this.touchX = 0;
		this.touchY = 0;
		this.touchesLen = 0;

		/* */
		const geometry = new THREE.BoxGeometry(4.6, 2.52, 1);
		//const geometry = new THREE.TubeGeometry();
		const material = new THREE.MeshBasicMaterial({ color: 0xfff000 });
		const cube = new THREE.Mesh(geometry, material);
		this.scene.add(cube);

		cube.position.x = 4;
		cube.position.z = 30;

		const geometry2 = new THREE.BoxGeometry(3, 2, 0.1);
		//const geometry = new THREE.TubeGeometry();
		const material2 = new THREE.MeshBasicMaterial({ color: 0xff000f });
		const cube2 = new THREE.Mesh(geometry2, material2);
		this.scene.add(cube2);

		cube2.position.x = 4;
		cube2.position.z = -50;

		/* EVENTS */
		document.addEventListener('mousemove', this.onMouseMove.bind(this));
		document.addEventListener('wheel', this.onWheel.bind(this));
		document.addEventListener('touchstart', this.onTouchstart.bind(this));
		document.addEventListener('touchmove', this.onTouchmove.bind(this));

		window.addEventListener('resize', () =>
			this.renderer.setSize(window.innerWidth, window.innerHeight)
		);

		/* */
		this.update();
	}

	/**
	 * Events
	 */
	onMouseMove(event) {
		if (this.mouseX === 0) {
			this.mouseX = event.clientX;
		} else {
			const mx = (event.clientX - this.mouseX) / 100;
			this.mouseX = event.clientX;
			this.cameraX += mx;
		}

		if (this.mouseY === 0) {
			this.mouseY = event.clientY;
		} else {
			const my = (event.clientY - this.mouseY) / -100;
			this.mouseY = event.clientY;
			this.cameraY += my;
		}
	}

	onWheel(event) {
		const i = event.deltaY > 0 ? 2 : -2;
		this.cameraZ = this.camera.position.z + i;
	}

	onTouchstart(event) {
		this.touchesLen = event.touches.length;
		if (this.touchesLen === 1) {
			this.touchX = event.touches[0].clientX;
			this.touchY = event.touches[0].clientY;
		} else if (this.touchesLen === 2) {
			this.pinchZoomDistance = Math.abs(event.touches[0].pageY - event.touches[1].pageY);
		}
	}

	onTouchmove(event) {
		if (this.touchesLen === 1) {
			const mx = (event.touches[0].clientX - this.touchX) / -10;
			const my = (event.touches[0].clientY - this.touchY) / 10;
			this.touchX = event.touches[0].clientX;
			this.touchY = event.touches[0].clientY;
			this.cameraX += mx;
			this.cameraY += my;
		} else if (this.touchesLen === 2) {
			const pzd = Math.abs(event.touches[0].pageY - event.touches[1].pageY);
			const min = Math.abs(pzd - this.pinchZoomDistance) * 10;
			if (min) {
				if (this.pinchZoomDistance !== pzd) {
					const i = pzd > this.pinchZoomDistance ? -1 : 1;
					this.cameraZ = this.camera.position.z + i * (min / 100);
				}
			}
			this.pinchZoomDistance = pzd;
		}
	}

	/**
	 * Render
	 */
	update() {
		this.camera.position.x = this.cameraX;
		this.camera.position.y = this.cameraY;
		this.camera.position.z = this.cameraZ;

		this.camera.lookAt(this.camera.position);

		this.renderer.render(this.scene, this.camera);
		requestAnimationFrame(this.update.bind(this));
	}
}

export default Space;
